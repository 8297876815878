import { Authentication } from "@sdi-one-core/console-app-web-utilities";

const OktaConfig = Authentication.getOktaConfig();
const SubAppOktaConfig = {
  ...OktaConfig,
  tokenManager: {
    autoRenew: false, // stop auto renew for all apps except okta-login App (1 app is responsible for updating tokens)
  },
};

export { OktaConfig, SubAppOktaConfig };
