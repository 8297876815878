import {
  registerApplication,
  start,
} from "single-spa/lib/esm/single-spa.min.js";
import { OktaConfig, SubAppOktaConfig } from "./utilities";

registerApplication(
  "@sdi-one-core/okta-login-console-app-web",
  () => System.import("@sdi-one-core/okta-login-console-app-web"),
  (location) => true,
  { oktaConfig: OktaConfig }
);

registerApplication(
  "@sdi-one-core/organization-console-app-web",
  () => System.import("@sdi-one-core/organization-console-app-web"),
  (location) =>
    location.pathname.startsWith("/organizations/") ||
    location.pathname === "/",
  {
    oktaConfig: SubAppOktaConfig,
  }
);

registerApplication(
  "@sdi-one-core/navigation-console-app-web",
  () => System.import("@sdi-one-core/navigation-console-app-web"),
  (location) => !location.pathname.startsWith("/implicit/callback"),
  { oktaConfig: SubAppOktaConfig }
);

registerApplication({
  name: "@sdi-one-core/accounts-details-console-app-web",
  app: () => System.import("@sdi-one-core/accounts-details-console-app-web"),
  activeWhen: "/accounts/:id/details",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElement: document.getElementById("account-level-app-window"),
  },
});

registerApplication({
  name: "@sdi-one-core/licensed-features-console-app-web",
  app: () => System.import("@sdi-one-core/licensed-features-console-app-web"),
  activeWhen: "/accounts/:id/licensed-features",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElement: document.getElementById("account-level-app-window"),
  },
});

registerApplication(
  "@sdi-one-core/roles-console-app-web",
  () => System.import("@sdi-one-core/roles-console-app-web"),
  (location) => location.pathname.startsWith("/roles"),
  { oktaConfig: SubAppOktaConfig }
);

registerApplication({
  name: "@sdi-one-reporting/account-reports-console-app-web",
  app: () =>
    System.import("@sdi-one-reporting/account-reports-console-app-web"),
  activeWhen: "/accounts/:id/reports",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElement: document.getElementById("account-level-app-window"),
  },
});

registerApplication({
  name: "@sdi-one-captive-portal/user-experiences-console-app-web",
  app: () =>
    System.import("@sdi-one-captive-portal/user-experiences-console-app-web"),
  activeWhen: "/accounts/:id/user-experiences",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElement: document.getElementById("account-level-app-window"),
  },
});

registerApplication({
  name: "@sdi-one-captive-portal/access-codes-console-app-web",
  app: () =>
    System.import("@sdi-one-captive-portal/access-codes-console-app-web"),
  activeWhen: "/accounts/:id/policies",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElement: document.getElementById("account-level-app-window"),
  },
});

registerApplication({
  name: "@sdi-one-end-user-auth/user-sessions-console-app-web",
  app: () =>
    System.import("@sdi-one-end-user-auth/user-sessions-console-app-web"),
  activeWhen: "/accounts/:id/user-devices",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElement: document.getElementById("account-level-app-window"),
  },
});

registerApplication({
  name: "@sdi-one-connectors/connectors-console-app-web",
  app: () => System.import("@sdi-one-connectors/connectors-console-app-web"),
  activeWhen: "/accounts/:id/connectors",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElement: document.getElementById("account-level-app-window"),
  },
});

registerApplication({
  name: "@sdi-one-core/tickets-console-app-web",
  app: () => System.import("@sdi-one-core/tickets-console-app-web"),
  activeWhen: "/accounts/:id/tickets",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElement: document.getElementById("account-level-app-window"),
  },
});

registerApplication({
  name: "@sdi-one-core/file-management-console-app-web",
  app: () => System.import("@sdi-one-core/file-management-console-app-web"),
  activeWhen: "/accounts/:id/files",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElement: document.getElementById("account-level-app-window"),
  },
});

registerApplication({
  name: "@sdi-one-core/locations-console-app-web",
  app: () => System.import("@sdi-one-core/locations-console-app-web"),
  activeWhen: "/accounts/:id/locations",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElement: document.getElementById("account-level-app-window"),
  },
});

registerApplication({
  name: "@sdi-one-reporting/react-po-account-dashboard-webapp",
  app: () =>
    System.import("@sdi-one-reporting/react-po-account-dashboard-webapp"),
  activeWhen: "/accounts/:id/dashboard",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElement: document.getElementById("account-level-app-window"),
  },
});

registerApplication({
  name: "@sdi-one-reporting/standalone-modal-console-app-web",
  app: () =>
    System.import("@sdi-one-reporting/standalone-modal-console-app-web"),
  activeWhen: (location) => location.pathname.startsWith("/standalone-modal"),
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElement: document.getElementById("standalone-reporting-modal"),
  },
});

registerApplication({
  name: "@sdi-one-hardware/nms-authorization-console-app-web",
  app: () =>
    System.import("@sdi-one-hardware/nms-authorization-console-app-web"),
  activeWhen: "/accounts/:id/local-compute",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElement: document.getElementById("account-level-app-window"),
  },
});

registerApplication({
  name: "@sdi-one-captive-portal/designer-template-selection",
  app: () =>
    System.import("@sdi-one-captive-portal/designer-template-selection"),
  activeWhen: (location) =>
    location.pathname.startsWith("/portal-designer/template-selection/"),
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElement: document.getElementById(
      "single-spa-application:@sdi-one-captive-portal/designer-template-selection"
    ),
  },
});

registerApplication({
  name: "@sdi-one-captive-portal/designer-template-customization-app",
  app: () =>
    System.import(
      "@sdi-one-captive-portal/designer-template-customization-app"
    ),
  activeWhen: (location) =>
    location.pathname.startsWith(
      "/portal-designer/template-customization-controls/"
    ),
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElement: document.getElementById(
      "single-spa-application:@sdi-one-captive-portal/designer-template-customization-app"
    ),
  },
});

registerApplication({
  name: "@sdi-one-mdu-apps/residents-list-console-app-web",
  app: () => System.import("@sdi-one-mdu-apps/residents-list-console-app-web"),
  activeWhen: "/accounts/:id/residents",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElement: document.getElementById("account-level-app-window"),
  },
});

/*
 *******************************************************************************
 * NETWORK MANAGEMENT APPS
 *******************************************************************************
 */

registerApplication({
  name: "@sdi-one-network-management/managed-devices-wrapper-console-app-web",
  app: () =>
    System.import(
      "@sdi-one-network-management/managed-devices-wrapper-console-app-web"
    ),
  activeWhen: [
    "/accounts/:id/managed-devices",
    "/accounts/:id/managed-devices#:app",
  ],
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElementGetter: () => document.getElementById("account-level-app-window"),
  },
});

registerApplication({
  name: "@sdi-one-network-management/managed-devices-console-app-web",
  app: () =>
    System.import(
      "@sdi-one-network-management/managed-devices-console-app-web"
    ),
  activeWhen: "/accounts/:id/managed-devices#devices",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElementGetter: () =>
      document.getElementById(
        "single-spa-application:@sdi-one-network-management/managed-devices-console-app-web"
      ),
  },
});

registerApplication({
  name: "@sdi-one-network-management/network-discovery-wrapper-console-app-web",
  app: () =>
    System.import(
      "@sdi-one-network-management/network-discovery-wrapper-console-app-web"
    ),
  activeWhen: [
    "/accounts/:id/network-discovery",
    "/accounts/:id/network-discovery#:app",
  ],
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElementGetter: () => document.getElementById("account-level-app-window"),
  },
});

registerApplication({
  name: "@sdi-one-network-management/network-discovery-criteria-console-app-web",
  app: () =>
    System.import(
      "@sdi-one-network-management/network-discovery-criteria-console-app-web"
    ),
  activeWhen: "/accounts/:id/network-discovery#criteria",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElementGetter: () =>
      document.getElementById(
        "single-spa-application:@sdi-one-network-management/network-discovery-criteria-console-app-web"
      ),
  },
});

registerApplication({
  name: "@sdi-one-network-management/network-discovery-devices-console-app-web",
  app: () =>
    System.import(
      "@sdi-one-network-management/network-discovery-devices-console-app-web"
    ),
  activeWhen: "/accounts/:id/network-discovery#devices",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElementGetter: () =>
      document.getElementById(
        "single-spa-application:@sdi-one-network-management/network-discovery-devices-console-app-web"
      ),
  },
});

registerApplication({
  name: "@sdi-one-network-management/network-discovery-vlans-console-app-web",
  app: () =>
    System.import(
      "@sdi-one-network-management/network-discovery-vlans-console-app-web"
    ),
  activeWhen: "/accounts/:id/network-discovery#vlans",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElementGetter: () =>
      document.getElementById(
        "single-spa-application:@sdi-one-network-management/network-discovery-vlans-console-app-web"
      ),
  },
});

registerApplication({
  name: "@sdi-one-network-management/network-discovery-subnets-console-app-web",
  app: () =>
    System.import(
      "@sdi-one-network-management/network-discovery-subnets-console-app-web"
    ),
  activeWhen: "/accounts/:id/network-discovery#subnets",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElementGetter: () =>
      document.getElementById(
        "single-spa-application:@sdi-one-network-management/network-discovery-subnets-console-app-web"
      ),
  },
});

registerApplication({
  name: "@sdi-one-network-management/networks-wrapper-console-app-web",
  app: () =>
    System.import(
      "@sdi-one-network-management/networks-wrapper-console-app-web"
    ),
  activeWhen: ["/accounts/:id/networks", "/accounts/:id/networks#:app"],
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElementGetter: () => document.getElementById("account-level-app-window"),
  },
});

registerApplication({
  name: "@sdi-one-network-management/credentials-console-app-web",
  app: () =>
    System.import("@sdi-one-network-management/credentials-console-app-web"),
  activeWhen: "/accounts/:id/networks#credentials",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElementGetter: () =>
      document.getElementById(
        "single-spa-application:@sdi-one-network-management/credentials-console-app-web"
      ),
  },
});

registerApplication({
  name: "@sdi-one-network-management/networks-console-app-web",
  app: () =>
    System.import("@sdi-one-network-management/networks-console-app-web"),
  activeWhen: "/accounts/:id/networks#networks",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElementGetter: () =>
      document.getElementById(
        "single-spa-application:@sdi-one-network-management/networks-console-app-web"
      ),
  },
});

registerApplication({
  name: "@sdi-one-network-management/vlans-console-app-web",
  app: () => System.import("@sdi-one-network-management/vlans-console-app-web"),
  activeWhen: "/accounts/:id/vlans",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElementGetter: () => document.getElementById("account-level-app-window"),
  },
});

registerApplication({
  name: "@sdi-one-network-management/port-provisioning-console-app-web",
  app: () =>
    System.import(
      "@sdi-one-network-management/port-provisioning-console-app-web"
    ),
  activeWhen: "/accounts/:id/port-provisioning",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElementGetter: () => document.getElementById("account-level-app-window"),
  },
});

registerApplication({
  name: "@sdi-one-end-user-auth/staff-devices-console-app-web",
  app: () =>
    System.import("@sdi-one-end-user-auth/staff-devices-console-app-web"),
  activeWhen: "/accounts/:id/staff-devices",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElementGetter: () => document.getElementById("account-level-app-window"),
  },
});

registerApplication({
  name: "@sdi-one-network-management/wireless-provisioning-console-app-web",
  app: () =>
    System.import(
      "@sdi-one-network-management/wireless-provisioning-console-app-web"
    ),
  activeWhen: "/accounts/:id/wireless-provisioning",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElementGetter: () => document.getElementById("account-level-app-window"),
  },
});

registerApplication({
  name: "@sdi-one-network-management/subnets-console-app-web",
  app: () =>
    System.import("@sdi-one-network-management/subnets-console-app-web"),
  activeWhen: "/accounts/:id/networks#subnets",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElementGetter: () =>
      document.getElementById(
        "single-spa-application:@sdi-one-network-management/subnets-console-app-web"
      ),
  },
});

registerApplication({
  name: "@sdi-one-network-management/dhcp-relays-console-app-web",
  app: () =>
    System.import("@sdi-one-network-management/dhcp-relays-console-app-web"),
  activeWhen: "/accounts/:id/networks#dhcp-relays",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElementGetter: () =>
      document.getElementById(
        "single-spa-application:@sdi-one-network-management/dhcp-relays-console-app-web"
      ),
  },
});

registerApplication({
  name: "@sdi-one-network-management/dhcp-scopes-console-app-web",
  app: () =>
    System.import("@sdi-one-network-management/dhcp-scopes-console-app-web"),
  activeWhen: "/accounts/:id/networks#dhcp-scopes",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElementGetter: () =>
      document.getElementById(
        "single-spa-application:@sdi-one-network-management/dhcp-scopes-console-app-web"
      ),
  },
});

registerApplication({
  name: "@sdi-one-network-management/monitored-devices-console-app-web",
  app: () =>
    System.import(
      "@sdi-one-network-management/monitored-devices-console-app-web"
    ),
  activeWhen: "/accounts/:id/monitored-devices",
  customProps: {
    oktaConfig: SubAppOktaConfig,
    domElement: document.getElementById("account-level-app-window"),
  },
});

/**********/

start();
